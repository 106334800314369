import { isIOS } from 'react-device-detect';
import classNames from 'classnames/bind';
import { Button, Link } from '../StepButtons';

import windowWidthDecorator from '../../../../decorators/windowWidthDecorator';
import withComponentName from '../../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const ControlPanel = ({
  sendSkipAction, _handleClickBack, _handleClickNext, windowWidth, activePage,
  _handleClickRegister,
  activeBtn: activeBtnSubmit, disabledWLButton, valueRegisterButton,
}) => {
  const registerPage = activePage === 'Register';

  return (
    <div className={cx('control-panel')}>
      <Button
        className={cx('d-md-block d-lg-block d-xl-block',
          'back', { ios: isIOS }, { hide: activePage === 'Watchlist' })}
        onBtnClick={_handleClickBack}
        value="back"
        text="Back"
        width={windowWidth}
      />
      {!registerPage && (
        <Button
          className={cx('next', { save: activePage !== 'Watchlist' }, { disabled: disabledWLButton })}
          onBtnClick={_handleClickNext}
          value="next"
          arrow
          text="Next"
          width={windowWidth}
          disabled={disabledWLButton}
        />
      )}
      {registerPage && (
        <>
          <Link
            url="/watchlists"
            className={cx('skip')}
            onBtnClick={sendSkipAction}
            value="Skip"
            text="Skip"
          />
          <Button
            className={cx('next',
              { register: valueRegisterButton === 'Register' },
              { signin: valueRegisterButton === 'Sign in' },
              { next_disabled: !activeBtnSubmit })}
            onBtnClick={_handleClickRegister}
            value={valueRegisterButton}
            type="submit"
            text={valueRegisterButton}
            width={windowWidth}
          />
        </>
      )}
    </div>
  );
};

export default withComponentName(windowWidthDecorator(ControlPanel));
