import Styles from './styles.module.scss';
import withComponentName from '../../../../decorators/withComponentName';

import closeMsgModal from '../../../../../../assets/images/icons/promo-bar-close-button.png'

const SavedChangesMessageModal = ({ msgModalIsOpen, onMsgModalClose }) => (
  <div
    className={msgModalIsOpen ? Styles.wlMsgModalOpen : Styles.wlMsgModalClose}
    onClick={onMsgModalClose}
  >
    Your personalisation settings have been updated
    <img src={closeMsgModal} alt="icon" />
  </div>
);

export default withComponentName(SavedChangesMessageModal);
