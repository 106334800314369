import withComponentName from '../../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const ModalContent = ({
  wathclistHeading,
  _toNextPage,
  closeModal,
  closeIcon,
  watchlistMainText,
}) => (
  <div className={Styles.step_modal_inner}>
    {wathclistHeading()}
    <p>
      {watchlistMainText()}
    </p>
    <div className={`d-flex justify-content-center ${Styles.step_modal_buttons}`}>
      <button
        onClick={_toNextPage}
        type="button"
      >
        Continue anyway
      </button>
      <button
        className={Styles.close}
        onClick={closeModal}
        type="button"
      >
        Ok, I&apos;ll select some
      </button>
    </div>
    <img
      className={Styles.close_icon}
      src={closeIcon}
      alt="icon"
      onClick={(e) => closeModal(e)}
    />
  </div>
);

export default withComponentName(ModalContent);
