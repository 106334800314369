import { Link as RouteLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';

export const Button = ({
  value, className, onBtnClick, width, text, arrow, disabled,
}) => (
  <button
    className={className}
    onClick={onBtnClick}
    type="button"
    disabled={disabled}
  >
    {((value === 'cancel' || value === 'back')) && (
      <FontAwesomeIcon icon={faAngleLeft} />
    )}
    {((width <= 768 && (value === 'save' || value === 'next' || value === 'skip' || value === 'Register' || value === 'Sign in')) || (width >= 768)) && (
      <>
        {text}
      </>
    )}
    {value === 'next' && arrow && (
      <FontAwesomeIcon icon={faAngleRight} />
    )}
  </button>
);

export const Link = ({
  value, className, onBtnClick, url, text, width,
}) => (
  <RouteLink to={url} className={className} onClick={onBtnClick}>
    {((value === 'cancel') || (value === 'cancelNoArrow' && width <= 768)) && (
      <FontAwesomeIcon icon={faAngleLeft} />
    )}
    {value !== 'cancelClose' && (
      <>
        {text}
      </>
    )}
    {value === 'next' && (
      <FontAwesomeIcon icon={faAngleRight} />
    )}
    {value === 'cancelClose' && (
      <FontAwesomeIcon icon={faXmark} />
    )}
  </RouteLink>
);
