import {
  isIOS,
} from 'react-device-detect';
import classNames from 'classnames/bind';
import { Button } from '../StepButtons';

import withComponentName from '../../../../decorators/withComponentName';
import windowWidthDecorator from '../../../../decorators/windowWidthDecorator';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const UserControlPanel = ({
  activePage,
  _handleClickNext,
  windowWidth,
  history,
  disabledWLButton,
}) => (
  activePage !== 'Register' ? (
    <div className={cx('user-control-panel')}>
      {activePage !== 'Watchlist' && (
        <Button
          className={cx('d-md-block d-lg-block d-xl-block', 'back', { ios: isIOS })}
          value="back"
          text={windowWidth >= 768 && 'Back'}
          onBtnClick={() => history.goBack()}
          width={windowWidth}
        />
      )}
      <Button
        className={cx('next', { finish: activePage === 'Preferences' }, { disabled: disabledWLButton })}
        onBtnClick={_handleClickNext}
        value="next"
        arrow={windowWidth < 768 || activePage !== 'Preferences'}
        text={activePage === 'Preferences' ? ((windowWidth >= 768 && ('Save and Finish')) || 'Finish ') : 'Next'}
        width={windowWidth}
      />
    </div>
  ) : null
);

export default withComponentName(windowWidthDecorator(UserControlPanel));
