import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import { isMobileOnly } from 'react-device-detect';
import isEqual from 'lodash-es/isEqual';

import * as searchActions from '../../../actions/searchActions';
import * as watchlistActions from '../../../actions/watchlistActions';
import * as storyFiltersActions from '../../../actions/storyFilters.action';

import closeIcon from '../../../assets/close-modal-icn.png';

import withComponentName from '../../../decorators/withComponentName';

import { searchTypeListWatchlistMapping, BASIC_SEARCH } from '../../../data/searchbar/types';

import { formatTopicsQuery } from '../../../helpers/watchlistHelperFunctions';
import { createGuestWatchlist } from '../../../helpers/guestUsersWatchlists';
import { saveSegmentsSuggestion } from '../../../services/dbServices/onboardingDbData';
import { piwikSearchbarTopicsFormatter } from '../../../helpers/helpers';
import { createNewPrivateCompaniesForWatchlist } from '../../../helpers/topicsApiHelpers';

import ControlPanel from './ControlPanel';
import UserControlPanel from './UserControlPanel';
import ModalContent from './ModalContent';
import SavedChangesMessageModal from './SavedChangesMessageModal';

import StorageSvc from '../../../services/StorageSvc';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class OnboardingStepNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      msgModalIsOpen: false,
      newWlTopics: [],
      userKeywords: '',
    };
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    const { newWatchlistTopics, userKeywords } = this.props;

    if (!isEqual(prevProps.newWatchlistTopics, newWatchlistTopics)) {
      this.setState({ newWlTopics: newWatchlistTopics });
    }

    if (!isEqual(prevProps.userKeywords, userKeywords)) {
      this.setState({ userKeywords });
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  sendOnboardingActions(action) {
    const { piwikEnabled } = this.props;

    if (piwikEnabled) _paq.push(['trackEvent', 'Onboarding', action]);
  }

  async handleClickNext(nameWl = '') {
    const {
      activePage, history,
      token,
      actions,
      activeSearchType, watchlistsList, piwikEnabled,
      openNameWlModal,
    } = this.props;
    const { newWlTopics, userKeywords } = this.state;

    if (activePage === 'Watchlist') {
      if (watchlistsList?.length) {
        // user has watchlists and manageWl part is opened and onclick he goes to prefernces step
        history.push('/news/onboarding/preferences');
      } else {
        // check nameWl value, cause might be SynteticObject
        let watchlistName = '';
        watchlistName = nameWl;
        if (typeof nameWl !== 'string') {
          watchlistName = '';
          // stertin create wl if there are topics or keywords
          if ((newWlTopics?.length > 0) || userKeywords?.trim().length) {
            openNameWlModal(true);
            return;
          }
        }
        // if user didnt select any topic or keyWord show modal
        if (!newWlTopics?.length && (!userKeywords || !userKeywords?.trim())) {
          this.openModal();
          return;
        }

        const msToRun = 2000; // 2 seconds
        const t0 = performance.now();

        let newWatchlistTopics = newWlTopics;
        if (activeSearchType === BASIC_SEARCH || !token) {
          newWatchlistTopics = await createNewPrivateCompaniesForWatchlist(newWlTopics);
        }

        // user doesnt have watchlists, wl creates
        if (token && ((newWatchlistTopics?.length > 0) || userKeywords?.trim().length)) {
          const query = formatTopicsQuery(newWatchlistTopics, userKeywords);

          await actions.createNewWatchlist(query, token, watchlistName);
          if (piwikEnabled) _paq.push(['trackEvent', 'WL page', 'Create new WL', piwikSearchbarTopicsFormatter(newWatchlistTopics)]);
        } else if (newWatchlistTopics?.length || /[a-zA-Z]/g.test(userKeywords)) {
          actions.fetchStoriesBegin();

          if (piwikEnabled) _paq.push(['trackEvent', 'WL page', 'Create new WL', piwikSearchbarTopicsFormatter(newWatchlistTopics)]);
          await createGuestWatchlist({
            topics: newWatchlistTopics,
            userQuery: userKeywords,
            watchlistName,
            activeSearchType,
          }).then((res) => {
            if (res) actions.guestUserWatchlists();
          }).catch((err) => {
            console.log(err);
          });
        }

        if (performance.now() - t0 < msToRun) {
          await new Promise((resolve) => setTimeout(() => resolve(), performance.now() - t0));
        }

        actions.newWatchlistCreationSuccess();
        this.resetWlKeyWordsState();
      }
    }

    if (activePage === 'Preferences') {
      try {
        const stateCach = (JSON.parse(StorageSvc.getItem('state'))) || {};
        const userSimplifiedView = stateCach.storyFilters[isMobileOnly ? 'user_newsfeed_advanced_view' : 'user_newsfeed_advanced_view_web'];
        StorageSvc.setItem('userSimplifiedView', userSimplifiedView);
      } catch (error) {
        console.log(error);
      }

      if (token) {
        history.push('/watchlists');
      } else {
        history.push('/news/onboarding/registration');
      }
      actions.toggleOnboardingProcess(false);
    }
  }

  handleClickBack() {
    const { activePage, history, token } = this.props;

    if (activePage === 'Register') {
      if (!token) this.sendOnboardingActions('Back (Register)');
      if (token) this.sendOnboardingActions('Cancel (Register)');

      history.push('/news/onboarding/preferences');
    } else if (activePage === 'Preferences') {
      if (!token) this.sendOnboardingActions('Back (Preferences)');
      if (token) this.sendOnboardingActions('Cancel (Preferences)');

      history.push('/news/onboarding/watchlist');
    }
  }

  resetWlKeyWordsState() {
    this.setState({
      newWlTopics: [],
      userKeywords: '',
    });
  }

  toNextPage() {
    const {
      activePage, history,
    } = this.props;

    if (activePage === 'Watchlist') return history.push('/news/onboarding/preferences');
    if (activePage === 'Preferences') return history.push('/news/onboarding/registration');
  }

  sendSkipAction() {
    const { activePage, piwikEnabled } = this.props;

    if (piwikEnabled) {
      if (activePage === 'Watchlist') {
        _paq.push(['trackEvent', 'Onboarding', 'Skip (Create WL)']);
      } else if (activePage === 'Preferences') {
        _paq.push(['trackEvent', 'Onboarding', 'Skip (Preferences)']);
      } else if (activePage === 'Finish') {
        _paq.push(['trackEvent', 'Onboarding', 'Skip (Registration)']);
      }
    }
  }

  saveUsersSuggestion() {
    const { storedSuggestion, suggestionType } = this.props;

    if (!storedSuggestion) return;

    const data = {
      name: storedSuggestion,
      type: suggestionType,
    };
    saveSegmentsSuggestion(data).then(() => {});
  }

  onUserWLSkip() {
    const { piwikEnabled } = this.props;

    StorageSvc.setItem('user_changes_saved', 'true');
    if (piwikEnabled) _paq.push(['trackEvent', 'Onboarding', 'Skip (Create WL)']);
  }

  // #Save user's watchlists
  async saveWatchlistAssetsData() {
    const {
      newWatchlistTopics: tempTopicsList,
      actions, watchlistCreationBegin, token,
      history, activeSearchType,
    } = this.props;

    this.sendOnboardingActions('Save (Create WL)');

    if (!tempTopicsList.length) return this.openModal();

    if (token) {
      let newWatchlistTopics = tempTopicsList;
      if (activeSearchType === BASIC_SEARCH || !token) {
        newWatchlistTopics = await createNewPrivateCompaniesForWatchlist(tempTopicsList);
      }
      const query = formatTopicsQuery(newWatchlistTopics);
      await actions.createNewWatchlist(query, token);

      setTimeout(() => {
        if (!watchlistCreationBegin) {
          history.push('/news/onboarding/preferences');
        }
      }, 500);
    }
    StorageSvc.setItem('user_changes_saved', true);
  }

  onSaveData() {
    const { activePage } = this.props;

    if (activePage === 'Watchlist') return this.saveWatchlistAssetsData();
  }

  onMsgModalClose() {
    this.setState({
      msgModalIsOpen: false,
    });
  }

  render() {
    const {
      openOnboardingWlModal,
      activePage,
      token,
      history,
      cookiesPolicyStatus,
      handleClickRegister,
      activeBtn,
      disabledWLButton,
      valueRegisterButton,
      manageWatchlists,
    } = this.props;
    const { modalIsOpen, msgModalIsOpen } = this.state;

    const wathclistHeading = () => {
      if (activePage === 'Watchlist') return <h4>You haven't selected any topics</h4>;
      return <h4>You haven't selected any option</h4>;
    };

    const watchlistMainText = () => {
      if (activePage === 'Watchlist') {
        return `Watchlists are integral to the CityFALCON experience.
        Build one now to maximise your benefits`;
      }
      return 'Selecting an option will help us personalise your news feed.';
    };

    return (
      <>
        <SavedChangesMessageModal
          msgModalIsOpen={msgModalIsOpen}
          onMsgModalClose={this.onMsgModalClose}
        />
        <div className={`navigation ${cx('navigation',
          { cookies_policy: cookiesPolicyStatus },
          activePage,
          { 'manage-watchlists': manageWatchlists })}`}
        >
          {token ? (
            <UserControlPanel
              history={history}
              activePage={activePage}
              onSaveData={this.onSaveData}
              onUserWLSkip={this.onUserWLSkip}
              _handleClickNext={this.handleClickNext}
              sendOnboardingActions={this.sendOnboardingActions}
              watchlistPageStep={activePage === 'Watchlist'}
              disabledWLButton={disabledWLButton}
            />
          ) : (
            <ControlPanel
              sendSkipAction={this.sendSkipAction}
              _handleClickBack={this.handleClickBack}
              _handleClickNext={this.handleClickNext}
              _handleClickRegister={handleClickRegister}
              activeBtn={activeBtn}
              activePage={activePage}
              disabledWLButton={disabledWLButton}
              valueRegisterButton={valueRegisterButton}
            />
          )}
          <Modal
            show={modalIsOpen || openOnboardingWlModal}
            onHide={this.closeModal}
            dialogClassName={Styles.modal}
            centered
          >
            <ModalContent
              wathclistHeading={wathclistHeading}
              closeModal={this.closeModal}
              _toNextPage={this.toNextPage}
              closeIcon={closeIcon}
              watchlistMainText={watchlistMainText}
            />
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  newWatchlistTopics: state.searchReducers[
    searchTypeListWatchlistMapping[state.searchReducers.activeSearchType]
  ],
  userKeywords: state.searchReducers.userKeywords,
  token: state.watchlistReducer.userToken,
  watchlistCreationBegin: state.watchlistReducer.watchlistCreationBegin,
  openOnboardingWlModal: state.searchReducers.openOnboardingWlModal,
  activeSearchType: state.searchReducers.activeSearchType,
  cookiesPolicyStatus: state.common.cookiesPolicyStatus,
  watchlistsList: state.watchlistReducer.watchlistsList,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...searchActions,
    ...watchlistActions,
    ...storyFiltersActions,
  }, dispatch),
});

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OnboardingStepNav),
);
